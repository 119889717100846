import {
  Counter,
  CyclabilityZone,
  Flow,
  ScenarioDetails,
  TH3Flow,
  TSectionFacility,
  TStoppingAreasFeatureCollection,
  TSuddenBrakingsFeatureCollection,
  currentMonth,
  prevMonth,
} from '@geovelo-frontends/commons';
import moment from 'moment';
import { ReactNode, useContext, useRef, useState } from 'react';

import { AppContext } from '../../app/context';
import { IPeriodFormValues } from '../../components/form/period';
import { ISliderBounds, TSliderRange } from '../../components/form/slider';
import { TResolution } from '../../hooks/map/h3';
import useQueryParams from '../../hooks/query-params';
import PageContainerLayout from '../../layouts/page/container';
import { TSectionFeatureCollection, TSectionProperties } from '../../models/sections';
import { IDashboardPage } from '../dashboard-page';

import CountersFormTab from './components/counter-form';
import CountersListTab from './components/counters-list';
import ExtrapolationTab from './components/extrapolation';
import FluidityTab from './components/fluidity';
import FluidityAnalysisTab from './components/fluidity-analysis';
import OldOriginDestinationTab from './components/old-origin-destination';
import OriginDestinationTab from './components/origin-destination';
import OriginDestinationAnalysisTab from './components/origin-destination-analysis';
import RoadsUseAnalysisTab from './components/roads-use-analysis';
import RoadsUseNewTab from './components/roads-use-new';
import ScenariosTab from './components/scenarios';
import SuddenBrakingsTab from './components/sudden-brakings';
import {
  IActivityStatsData,
  IBicycleObservatoryPageContext,
  TCounterStats,
  TCounterType,
} from './context';
import { TH3CellFeatureProps } from './models/origin-destination';

function BicycleObservatoryPage(page: IDashboardPage): JSX.Element {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { searchParams, getPeriods } = useQueryParams();

  // header context
  const [prevButtonClick, setPrevButtonClick] = useState<() => () => void>();
  const [actions, setActions] = useState<ReactNode>();
  const [title, setTitle] = useState<ReactNode>();

  // period context
  const [defaultPeriods] = useState(() =>
    getPeriods(moment().get('date') <= 7 ? prevMonth : currentMonth),
  );
  const [periods, setPeriods] = useState<IPeriodFormValues>(defaultPeriods);
  const [periodsComparisonEnabled, enablePeriodsComparison] = useState(
    searchParams.get('compare') === 'true',
  );

  // average speeds context
  const [averageSpeedsData, setAverageSpeedsData] = useState<TSectionFeatureCollection | null>();
  const [averageSpeedsSelectedFacilities, selectAverageSpeedsFacilities] = useState<
    TSectionFacility[]
  >([
    'cycleway',
    'lane',
    'greenway',
    'opposite',
    'sharebusway',
    'mixedfacilities',
    'other',
    'none',
  ]);
  const [averageSpeedsBounds, setAverageSpeedsBounds] = useState<ISliderBounds>();
  const [averageSpeedsCurrentRange, setAverageSpeedsCurrentRange] = useState<TSliderRange>();
  const [averageSpeedsQuartiles, setAverageSpeedsQuartiles] = useState<number[]>();
  const [globalAverageSpeed, setGlobalAverageSpeed] = useState<number>();
  const [prevGlobalAverageSpeed, setPrevGlobalAverageSpeed] = useState<number>();
  const [sectionsCountBySpeed, setSectionsCountBySpeed] = useState<number[]>();
  const [sectionsComparedToAverage, setSectionsComparedToAverage] = useState<number[]>();
  const [averageSpeedsTotalDistanceTraveled, setAverageSpeedsTotalDistanceTraveled] =
    useState<number>();
  const [averageSpeedsPrevTotalDistanceTraveled, setAverageSpeedsPrevTotalDistanceTraveled] =
    useState<number>();

  // old origin destination context
  const [oldOriginDestinationBounds, setOldOriginDestinationBounds] = useState<ISliderBounds>();
  const [oldOriginDestinationCurrentRange, setOldOriginDestinationCurrentRange] =
    useState<TSliderRange>();
  const [oldOriginDestinationZones, setOldOriginDestinationZones] = useState<CyclabilityZone[]>();
  const [oldOriginDestinationExternalZones, setOldOriginDestinationExternalZones] =
    useState<CyclabilityZone[]>();
  const [originDestinationSelectedZoneId, selectOriginDestinationZoneId] = useState<number | null>(
    null,
  );
  const [internalFlowsCount, setInternalFlowsCount] = useState<number>(0);
  const [externalFlowsCount, setExternalFlowsCount] = useState<number>(0);
  const [otherInternalFlowsCount, setOtherInternalFlowsCount] = useState<number>(0);
  const [oldOriginDestinationFlows, setOldOriginDestinationFlows] = useState<Flow[]>();
  const oldOriginDestinationCanvasRef = useRef<HTMLCanvasElement>(null);

  // origin destination context
  const [originDestinationMaxFlowsCount, setOriginDestinationMaxFlowsCount] = useState<number>();
  const [originDestinationBounds, setOriginDestinationBounds] = useState<ISliderBounds>();
  const [originDestinationCurrentRange, setOriginDestinationCurrentRange] =
    useState<TSliderRange>();
  const originDestinationCanvasRef = useRef<HTMLCanvasElement>(null);
  const [originDestinationZones, setOriginDestinationZones] = useState<CyclabilityZone[]>();
  const [originDestinationExternalZones, setOriginDestinationExternalZones] =
    useState<CyclabilityZone[]>();
  const [originDestinationH3Resolutions, setOriginDestinationH3Resolutions] = useState<
    TResolution | 'cyclabilityZones'
  >(7);
  const [originDestinationH3Features, setOriginDestinationH3Features] =
    useState<Array<GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon, TH3CellFeatureProps>>>();
  const [originDestinationExternalH3Features, setOriginDestinationExternalH3Features] =
    useState<Array<GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon, TH3CellFeatureProps>>>();
  const [originDestinationSelectedH3Indices, selectOriginDestinationH3Indices] = useState<string[]>(
    [],
  );
  const [originDestinationFlows, setOriginDestinationFlows] = useState<TH3Flow[]>();

  // roads use context
  const [roadsUseData, setRoadsUseData] = useState<TSectionFeatureCollection | null>();
  const [roadsUseSelectedFacilities, selectRoadsUseFacilities] = useState<TSectionFacility[]>([
    'cycleway',
    'lane',
    'greenway',
    'opposite',
    'sharebusway',
    'mixedfacilities',
    'other',
    'none',
  ]);
  const [roadsUseBounds, setRoadsUseBounds] = useState<ISliderBounds>();
  const [roadsUseCurrentRange, setRoadsUseCurrentRange] = useState<TSliderRange>();
  const [roadsUseQuartiles, setRoadsUseQuartiles] = useState<number[]>();
  const [roadsUseTotalDistanceTraveled, setRoadsUseTotalDistanceTraveled] = useState<number>();
  const [roadsUseDistancesByFacilities, setRoadsUseDistancesByFacilities] =
    useState<{ [key in TSectionFacility | 'all' | 'allExtrapolated']: number }>();
  const [roadsUsePrevDistancesByFacilities, setRoadsUsePrevDistancesByFacilities] =
    useState<{ [key in TSectionFacility | 'all' | 'allExtrapolated']: number }>();
  const [selectedSection, selectSection] = useState<TSectionProperties>();

  // traffic extrapolation context
  const [trafficExtrapolationData, setTrafficExtrapolationData] =
    useState<TSectionFeatureCollection | null>();
  const [trafficExtrapolationSelectedFacilities, selectTrafficExtrapolationFacilities] = useState<
    TSectionFacility[]
  >([
    'cycleway',
    'lane',
    'greenway',
    'opposite',
    'sharebusway',
    'mixedfacilities',
    'other',
    'none',
  ]);
  const [trafficExtrapolationBounds, setTrafficExtrapolationBounds] = useState<ISliderBounds>();
  const [trafficExtrapolationCurrentRange, setTrafficExtrapolationCurrentRange] =
    useState<TSliderRange>();
  const [trafficExtrapolationQuartiles, setTrafficExtrapolationQuartiles] = useState<number[]>();
  const [trafficExtrapolationTotalDistanceTraveled, setTrafficExtrapolationTotalDistanceTraveled] =
    useState<number>();
  const [trafficExtrapolationDistancesByFacilities, setTrafficExtrapolationDistancesByFacilities] =
    useState<{ [key in TSectionFacility | 'all' | 'allExtrapolated']: number }>();

  // stopping areas context
  const [stoppingAreasData, setStoppingAreasData] =
    useState<TStoppingAreasFeatureCollection | null>();
  const [stoppingAreasQuartiles, setStoppingAreasQuartiles] = useState<number[]>();
  const [stoppingAreasBounds, setStoppingAreasBounds] = useState<ISliderBounds>();
  const [stoppingAreasSecondaryBounds, setStoppingAreasSecondaryBounds] = useState<ISliderBounds>();
  const [stoppingAreasCurrentRange, setStoppingAreasCurrentRange] = useState<TSliderRange>();
  const [stoppingAreasSecondRange, setStoppingAreasSecondRange] = useState<TSliderRange>();

  // sudden brakings context
  const [suddenBrakingsData, setSuddenBrakingsData] =
    useState<TSuddenBrakingsFeatureCollection | null>();
  const [suddenBrakingsBounds, setSuddenBrakingsBounds] = useState<ISliderBounds>();
  const [suddenBrakingsSecondaryBounds, setSuddenBrakingsSecondaryBounds] =
    useState<ISliderBounds>();

  // counters context
  const [countersTypes, setCountersTypes] = useState<TCounterType[]>(
    currentPartner?.dashboardTabsPermissions.usagePointAttendance === 'extrapolated'
      ? ['virtual', 'real']
      : ['virtual'],
  );
  const [counters, setCounters] = useState<Counter[]>();
  const [counterSelectedKey, selectCounterKey] = useState<string | null>(null);
  const [counterStats, setCounterStats] = useState<TCounterStats[]>();
  const [editingCounter, setEditingCounter] = useState<Counter>();

  // activity stats context
  const [activityStatsData, setActivityStatsData] = useState<IActivityStatsData>();

  // scenario context
  const [nominalGraph, setNominalGraph] = useState<ScenarioDetails>();
  const [roadIdMap, setRoadIdMap] = useState<{ [id: number]: number }>({});

  return (
    <PageContainerLayout<IBicycleObservatoryPageContext>
      context={{
        defaultPeriods,
        header: {
          prevButtonClick,
          title,
          actions,
          setPrevButtonClick,
          setTitle,
          setActions,
        },
        period: {
          values: periods,
          comparisonEnabled: periodsComparisonEnabled,
          setValues: setPeriods,
          enableComparison: enablePeriodsComparison,
        },
        averageSpeeds: {
          data: averageSpeedsData,
          globalAverageSpeed,
          prevGlobalAverageSpeed,
          selectedFacilities: averageSpeedsSelectedFacilities,
          bounds: averageSpeedsBounds,
          quartiles: averageSpeedsQuartiles,
          currentRange: averageSpeedsCurrentRange,
          sectionsCountBySpeed,
          sectionsComparedToAverage,
          totalDistanceTraveled: averageSpeedsTotalDistanceTraveled,
          prevTotalDistanceTraveled: averageSpeedsPrevTotalDistanceTraveled,
          setData: setAverageSpeedsData,
          selectFacilities: selectAverageSpeedsFacilities,
          setBounds: setAverageSpeedsBounds,
          setQuartiles: setAverageSpeedsQuartiles,
          setCurrentRange: setAverageSpeedsCurrentRange,
          setGlobalAverageSpeed,
          setPrevGlobalAverageSpeed,
          setSectionsCountBySpeed,
          setSectionsComparedToAverage,
          setTotalDistanceTraveled: setAverageSpeedsTotalDistanceTraveled,
          setPrevTotalDistanceTraveled: setAverageSpeedsPrevTotalDistanceTraveled,
        },
        oldOriginDestination: {
          canvasRef: oldOriginDestinationCanvasRef,
          bounds: oldOriginDestinationBounds,
          currentRange: oldOriginDestinationCurrentRange,
          externalZones: oldOriginDestinationExternalZones,
          zones: oldOriginDestinationZones,
          selectedZoneId: originDestinationSelectedZoneId,
          flows: oldOriginDestinationFlows,
          internalFlowsCount,
          externalFlowsCount,
          otherInternalFlowsCount,
          selectZoneId: selectOriginDestinationZoneId,
          setBounds: setOldOriginDestinationBounds,
          setCurrentRange: setOldOriginDestinationCurrentRange,
          setFlows: setOldOriginDestinationFlows,
          setInternalFlowsCount,
          setExternalFlowsCount,
          setOtherInternalFlowsCount,
          setZones: setOldOriginDestinationZones,
          setExternalZones: setOldOriginDestinationExternalZones,
        },
        originDestination: {
          canvasRef: originDestinationCanvasRef,
          bounds: originDestinationBounds,
          maxFlowsCount: originDestinationMaxFlowsCount,
          currentRange: originDestinationCurrentRange,
          zones: originDestinationZones,
          externalZones: originDestinationExternalZones,
          h3Resolution: originDestinationH3Resolutions,
          h3Features: originDestinationH3Features,
          externalH3Features: originDestinationExternalH3Features,
          selectedH3Indices: originDestinationSelectedH3Indices,
          flows: originDestinationFlows,
          setBounds: setOriginDestinationBounds,
          setMaxFlowsCount: setOriginDestinationMaxFlowsCount,
          setCurrentRange: setOriginDestinationCurrentRange,
          setZones: setOriginDestinationZones,
          setExternalZones: setOriginDestinationExternalZones,
          setH3Resolution: setOriginDestinationH3Resolutions,
          setH3Features: setOriginDestinationH3Features,
          setExternalH3Features: setOriginDestinationExternalH3Features,
          selectH3Indices: selectOriginDestinationH3Indices,
          setFlows: setOriginDestinationFlows,
        },
        roadsUse: {
          data: roadsUseData,
          distancesByFacilities: roadsUseDistancesByFacilities,
          prevDistancesByFacilities: roadsUsePrevDistancesByFacilities,
          selectedFacilities: roadsUseSelectedFacilities,
          bounds: roadsUseBounds,
          quartiles: roadsUseQuartiles,
          currentRange: roadsUseCurrentRange,
          totalDistanceTraveled: roadsUseTotalDistanceTraveled,
          selectedSection,
          selectSection,
          setData: setRoadsUseData,
          setDistancesByFacilities: setRoadsUseDistancesByFacilities,
          setPrevDistancesByFacilities: setRoadsUsePrevDistancesByFacilities,
          selectFacilities: selectRoadsUseFacilities,
          setBounds: setRoadsUseBounds,
          setQuartiles: setRoadsUseQuartiles,
          setCurrentRange: setRoadsUseCurrentRange,
          setTotalDistanceTraveled: setRoadsUseTotalDistanceTraveled,
        },
        trafficExtrapolation: {
          data: trafficExtrapolationData,
          distancesByFacilities: trafficExtrapolationDistancesByFacilities,
          selectedFacilities: trafficExtrapolationSelectedFacilities,
          bounds: trafficExtrapolationBounds,
          quartiles: trafficExtrapolationQuartiles,
          currentRange: trafficExtrapolationCurrentRange,
          totalDistanceTraveled: trafficExtrapolationTotalDistanceTraveled,
          setData: setTrafficExtrapolationData,
          setDistancesByFacilities: setTrafficExtrapolationDistancesByFacilities,
          selectFacilities: selectTrafficExtrapolationFacilities,
          setBounds: setTrafficExtrapolationBounds,
          setQuartiles: setTrafficExtrapolationQuartiles,
          setCurrentRange: setTrafficExtrapolationCurrentRange,
          setTotalDistanceTraveled: setTrafficExtrapolationTotalDistanceTraveled,
        },
        stoppingAreas: {
          data: stoppingAreasData,
          quartiles: stoppingAreasQuartiles,
          bounds: stoppingAreasBounds,
          secondaryBounds: stoppingAreasSecondaryBounds,
          currentRange: stoppingAreasCurrentRange,
          secondaryRange: stoppingAreasSecondRange,
          setData: setStoppingAreasData,
          setQuartiles: setStoppingAreasQuartiles,
          setBounds: setStoppingAreasBounds,
          setSecondaryBounds: setStoppingAreasSecondaryBounds,
          setCurrentRange: setStoppingAreasCurrentRange,
          setSecondaryRange: setStoppingAreasSecondRange,
        },
        suddenBrakings: {
          data: suddenBrakingsData,
          bounds: suddenBrakingsBounds,
          secondaryBounds: suddenBrakingsSecondaryBounds,
          setData: setSuddenBrakingsData,
          setBounds: setSuddenBrakingsBounds,
          setSecondaryBounds: setSuddenBrakingsSecondaryBounds,
        },
        counters: {
          editingCounter,
          types: countersTypes,
          list: counters,
          selectedKey: counterSelectedKey,
          stats: counterStats,
          setTypes: setCountersTypes,
          setEditingCounter,
          setList: setCounters,
          selectKey: selectCounterKey,
          setStats: setCounterStats,
        },
        activityStats: {
          data: activityStatsData,
          setData: setActivityStatsData,
        },
        scenario: {
          nominalGraph,
          roadIdMap,
          setNominalGraph,
          setRoadIdMap,
        },
      }}
      page={page}
    />
  );
}

export {
  CountersFormTab,
  CountersListTab,
  ExtrapolationTab,
  FluidityTab,
  FluidityAnalysisTab,
  OldOriginDestinationTab,
  OriginDestinationTab,
  OriginDestinationAnalysisTab,
  RoadsUseNewTab,
  RoadsUseAnalysisTab,
  ScenariosTab,
  SuddenBrakingsTab,
};
export default BicycleObservatoryPage;
